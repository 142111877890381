.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #F50208;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid #F50208;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: #ffe6e6;
}

.ant-btn-primary {
    background: #F50208;
    border-color: #F50208;
}

a {
    color: #F50208;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
    color: #fff;
    background: #F50208;
    border-color: #F50208;
}
