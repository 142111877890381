/* React DatePicker */
.ant-picker-header-view button:hover {
  color: red;
}

.ant-picker-input input::placeholder {
  color: #333 !important;
}

.list-page-date-picker .ant-picker {
  margin: 5px auto !important;
}

@media only screen and (max-width: 450px) {
  .list-page-date-picker {
    padding-left: 5px !important;
  }

  .list-page-date-picker .ant-picker .ant-picker-input input {
    font-size: 0.75rem !important;
  }

  .ant-picker-datetime-panel {
    flex-direction: column;
  }

  .ant-picker-dropdown {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }

  .ant-picker-dropdown.ant-picker-dropdown-placement-bottomLeft.slide-up-enter.slide-up-enter-prepare.slide-up,
  .ant-picker-dropdown.ant-picker-dropdown-placement-bottomLeft.slide-up-leave.slide-up-leave-active.slide-up {
    top: -900px !important;
    left: -1000px !important;
  }
}

/* React Wrapper */
@media only screen and (max-width: 820px) {
  .react-select-wrapper {
    /* width: 49% !important; */
    /* margin-left: 5px !important; */
    margin-bottom: 10px !important;
  }

  .react-select-wrapper > div > div {
    min-width: unset !important;
    font-size: 13px !important;
  }
}

/* @media only screen and (max-width: 600px) {
  .react-select-wrapper {
    width: 48% !important;
  }
} */

/* Antd Input */
.ant-input[disabled] {
  color: black !important;
}

.ant-input:hover,
.ant-input:focus {
  border: 1px solid red !important;
  box-shadow: none !important;
}

.ant-input-affix-wrapper.ant-input-password .ant-input {
  border: none !important;
  box-shadow: none !important;
}

.ant-input-affix-wrapper.ant-input-password:hover {
  box-shadow: none !important;
}

/* Antd Collapse */
.ant-collapse-header {
  padding: 10px !important;
  text-align: center;
  width: 150px;
}

.ant-collapse.ant-collapse-icon-position-left.ant-collapse-ghost {
  background-color: #fff !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.view_with__popup .popup_handler .ant-btn svg {
  stroke-width: 15px;
}

/* Antd Button */
@media only screen and (max-width: 820px) {
  .react-select-wrapper {
    margin-bottom: 10px !important;
    margin-left: 3vw !important;
    width: 40vw !important;
  }

  .view_with__popup .popup_handler .ant-btn {
    margin: 0 !important;
    width: 40vw !important;
  }
}

@media only screen and (max-width: 680px) {
  .react-select-wrapper {
    margin-left: 2.5vw !important;
  }
}

@media only screen and (max-width: 640px) {
  .react-select-wrapper {
    margin-left: 2.1vw !important;
  }
}

@media only screen and (max-width: 550px) {
  .react-select-wrapper {
    margin-left: 2vw !important;
  }
}

@media only screen and (max-width: 490px) {
  .react-select-wrapper {
    margin-left: 1.8vw !important;
  }
}

/* @media only screen and (max-width: 420px) {
  .react-select-wrapper {
    margin-left: 1.6vw !important;
  }
} */

@media only screen and (max-width: 390px) {
  .react-select-wrapper {
    margin-left: 1.7vw !important;
  }
}

@media only screen and (max-width: 360px) {
  .react-select-wrapper {
    margin-left: 1.5vw !important;
  }
}

.ant-btn:hover,
.ant-btn:focus {
  color: red;
  border: 1px solid red;
}

.ant-btn.ant-btn-primary.ant-btn-sm.list-page-search-btn {
  margin-top: 3px !important;
}

.ant-btn.ant-btn-primary.ant-btn-sm {
  min-width: unset !important;
  padding: 0 15px !important;
}

.ant-btn.ant-btn-primary.ant-btn-sm.home-page-search-btn {
  width: 120px;
}

@media only screen and (max-width: 991px) {
  .ant-btn.ant-btn-primary.ant-btn-sm.home-page-search-btn {
    width: 100% !important;
    height: 45px !important;
  }
}

@media only screen and (max-width: 700px) {
  .ant-btn.ant-btn-primary.ant-btn-sm.list-page-search-btn {
    width: 100% !important;
    height: 40px !important;
    margin-top: 5px !important;
  }
}

@media only screen and (max-width: 500px) {
  .ant-btn.ant-btn-primary.ant-btn-sm.list-page-search-btn {
    height: 35px !important;
  }
  .ant-btn.ant-btn-primary.ant-btn-sm.home-page-search-btn {
    height: 40px !important;
  }

  .ant-btn.ant-btn-primary.ant-btn-sm {
    height: 35px !important;
  }
}

/* Antd Dropdown */
.ant-select-selection-item {
  color: black !important;
}

.ant-picker-dropdown {
  z-index: 99999 !important;
}

.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-picker-dropdown-placement-bottomLeft {
  z-index: 10005 !important;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  background-color: red;
  color: #fff;
}

/* Antd Tab */

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab:hover {
  color: red !important;
}

.ant-tabs-ink-bar {
  height: 5px;
  background: red !important;
}

.ant-tabs-nav {
  margin-bottom: 0 !important;
}

/* Antd Button */
.ant-override.ant-btn:hover,
.ant-override.ant-btn:focus {
  border: 1px solid red !important;
  color: red !important;
}

.ant-override-receipt.ant-btn,
.ant-override-receipt.ant-btn:active,
.ant-override-receipt.ant-btn:hover,
.ant-override-receipt.ant-btn:focus {
  border: 1px solid #6a747d !important;
  color: #fff !important;
  background: #6a747d !important;
}

/* Antd Pagination */
.ant-pagination-item-active a,
.ant-pagination-item:focus a,
.ant-pagination-item:hover a,
.ant-pagination-item-link:hover {
  color: red !important;
}

.ant-pagination-item-active,
.ant-pagination-item:hover,
.ant-pagination-item-link:hover {
  border: 1px solid red !important;
}

/* Google Map */

/* @media only screen and (max-width: 400px) {
  .ant-collapse.ant-collapse-icon-position-left.ant-collapse-ghost {
    height: 35px !important;
  }

  .ant-collapse-header{
    padding: 0;
    margin:0;
  }

  .media_wrapper {
    height: 120px;
  }

  .media_wrapper img {
    height: 100% !important;
  }

  .content_wrapper {
    padding-top: 1px !important;
    padding-bottom: 10px !important;
  }

  .meta_wrapper {
    margin-top: 8px !important;
  }

  .meta_wrapper .price span:first-child {
    font-size: 1rem !important;
  }

  .meta_wrapper .price span:last-child {
    font-size: 0.75rem !important;
  }
} */

.grid_card.info_window_card .media_wrapper {
  height: 170px !important;
}

.media_wrapper img {
  height: 100% !important;
}

/* FILTER SECTION */
.view_with__popup .popup_handler button,
.view_with__popup .popup_handler button.ant-btn {
  color: #2c2c2c !important;
}

@media only screen and (max-width: 430px) {
  /* MAP SIZE */
  .fixed-map {
    height: calc(100% - 140px) !important;
    top: 140px !important;
  }

  .direction-button {
    height: 23px !important;
  }

  /* FILTER BAR */
  /* .sticky-inner-wrapper {
    height: 40px !important;
  } */

  .ant-collapse.ant-collapse-icon-position-left.ant-collapse-ghost {
    height: 100% !important;
  }

  /* FILTER CONTENT */
  .ant-collapse-content {
    margin-top: -10px !important;
  }

  /* -- FILTER LABEL -- */
  .ant-collapse-header div:first-child {
    font-size: 15px !important;
  }

  /* -- SHOW MAP LABEL -- */
  .ant-checkbox-wrapper {
    margin-top: -5px !important;
  }

  .ant-checkbox-wrapper .show-map {
    font-size: 14px !important;
  }

  .price .price-unit {
    font-size: 0.8rem !important;
  }

  /* SEARCH BUTTON */
  .ant-btn.ant-btn-primary.ant-btn-sm.list-page-search-btn {
    height: 28px !important;
    font-size: 0.9rem !important;
  }

  .list-page-date-picker {
    margin: 0 !important;
    height: 25px !important;
  }

  .booking-type-react-select {
    height: 26px !important;
  }

  .grid_card.info_window_card .media_wrapper {
    height: 150px !important;
  }

  .media_wrapper img {
    height: 100% !important;
  }

  .content_wrapper {
    padding-top: 1px !important;
    padding-bottom: 8px !important;
  }

  .grid_card.info_window_card .meta_wrapper {
    margin-top: 15px !important;
  }
}

/* Info Window outer container */
.gm-style .gm-style-iw-c {
  overflow: visible !important;
  padding: 0px !important;
  max-height: unset !important;
  max-width: unset !important;
  -webkit-box-shadow: 10px 10px 28px 15px rgba(0, 0, 0, 0.19) !important;
  -moz-box-shadow: 10px 10px 28px 15px rgba(0, 0, 0, 0.19) !important;
  box-shadow: 10px 10px 28px 15px rgba(0, 0, 0, 0.19) !important;
}

/* Info Window inner container */
.gm-style-iw-d {
  overflow: unset !important;
  max-height: unset !important;
}

/* Info Window arrow on the bottom */
.gm-style .gm-style-iw-t::after {
  display: none !important;
}

.gm-ui-hover-effect {
  background-color: #fff !important;
  background-position: center !important;
  background-size: 20px 20px !important;
  background-repeat: no-repeat !important;
  border-radius: 50px !important;
  opacity: 1 !important;
  top: 3px !important;
  right: 3px !important;
  width: 25px !important;
  height: 25px !important;
}

.gm-ui-hover-effect img {
  margin: 0 !important;
  margin-left: 5px !important;
}

.register-phone-input label {
  color: #fff !important;
}

/* Ant Input With Affix */
/* .ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border: 1px solid red !important;
} */

/* .ant-input-affix-wrapper:hover .ant-input,
.ant-input-affix-wrapper:focus .ant-input,
.ant-input-affix-wrapper-focused .ant-input,
.field-label:hover .ant-input-affix-wrapper,
.field-label:focus .ant-input-affix-wrapper,
.field-label:hover .ant-input-affix-wrapper .ant-input,
.field-label:focus .ant-input-affix-wrapper .ant-input,
.ant-input {
  border: none !important;
} */

#select-suffix svg,
.is-validated .ant-input-suffix svg {
  width: 25px;
  height: 25px;
  color: #28a745;
}

/* .ant-input-affix-wrapper-focused {
  box-shadow: unset !important;
} */

.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus {
  border: 1px solid #d9d9d9 !important;
}

.ant-input-affix-wrapper .ant-input,
.ant-input-affix-wrapper:hover .ant-input,
.ant-input-affix-wrapper:focus .ant-input,
#activation-form .ant-input {
  border: none !important;
  border-color: none !important;
}
